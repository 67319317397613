import React from 'react';
import Homepage from './pages/Homepage'
import Code from './pages/Code'

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />}>
          </Route>
          <Route path="/code/:id" element={<Code />}>
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
