import { ethers } from "ethers";

declare let window: any;
interface ConnectInfo {
    chainId: string;
}

const listAccounts = async (): Promise<string[]> => {
    let accounts: Array<string> = [];
    if (window.ethereum && window.ethereum.isConnected()) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        accounts = await provider.listAccounts();
    }
    return accounts;
}

const getContract = async (address: string, abi: any): Promise<ethers.Contract> => {
    if (window.ethereum && window.ethereum.isConnected()) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const accounts = await listAccounts();
        let signer = (accounts.length == 0)? provider : provider.getSigner();
        return await new ethers.Contract(address, abi, signer)
    }
    return Promise.reject(new Error('Ethereum namespace does not exist'));
}

const loginToMetamask = async (): Promise<string[]> => {
    return await window.ethereum.request({
        method: "eth_requestAccounts",
    });
}

// TODO: this is failing
const mempoolListener = async () => {
    if (window.ethereum && window.ethereum.isConnected()) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        provider.on("pending", (tx: any) => {
            console.log(tx)
        });

        provider.on("block", (blockNumber: any) => {
            console.log(blockNumber)
        })
    }
}

const connectionHandler = async (delegate: ((...args: any[]) => any)) => {
    if (window.ethereum.isConnected()) {
        delegate();
    }
  
    // When metamask updates account state
    window.ethereum.on('accountsChanged', async (accounts: any) => {
        delegate();
    })
  
    // When metamask updates network state
    window.ethereum.on('chainChanged', async (networkId: any) => {
        delegate();
    })
    
    // When metamask connects
    window.ethereum.on('connect', (connectInfo: ConnectInfo) => {
        delegate();
    });
    
    // When the page loads
    window.addEventListener('load', async () => {
        delegate();
    });
}

export {
    connectionHandler,
    getContract,
    listAccounts,
    loginToMetamask,
    mempoolListener
}
