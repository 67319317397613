import React from 'react';
import style from './index.module.scss'

import {
  connectionHandler,
  listAccounts,
  loginToMetamask
} from '../../utils'

interface HomePageProps {}
interface HomePageState {
    walletValue: string
}

class Homepage extends React.Component<HomePageProps, HomePageState> {
  state = {
    walletValue: ''
  }

  handleChange = (e: any) => {
    this.setState({ walletValue: e.target.value })
  }

  render() {
    return (
      <div className={style.App}>
        <div className={style.Title}>
          coinbass
        </div>
        <div className={style.Content}>
        <button
          className={style.ConnectCoinbase}
          onClick={async () => {
            try {
              await loginToMetamask()
              const accounts = await listAccounts();
              window.location.href = 'code/' + accounts[0]
            } catch(err: any) {
            }
          }}>
          CONNECT TO METAMASK
        </button>
        <hr className={style.Motion}/>
        <div className={style.Manual}>
          <input
            onChange={(e) => {this.handleChange(e)}} 
            className={style.Custom}
            placeholder={"WALLET ADDRESS"} />
          <button
            className={style.Generate}
            onClick={() => {
              if(this.state.walletValue != '') {
                window.location.href = 'code/' + this.state.walletValue
              }
            }}>
            GENERATE
          </button>
        </div>
        </div>
        <div className={style.BottomBar}>
        PAID FOR BY COINBASS &copy; 2022 | COINBASS, INC.
        </div>
      </div>
    );
  }
}

export default Homepage;
