import React, { useState } from 'react';
import style from './index.module.scss'

import { useParams } from "react-router-dom";
import { QRCode } from 'react-qr-svg';

const COLORS = ["#BCB844", "#EB0E29", "#C10FB0", "#0FBC42", "#0FBCBB"]
const QR_RECT = 150;
function Code() {
  const [count, setCount] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  let params = useParams();

  var x = 0;
  var y = 0;
  var directionX = 0;
  var directionY = 0;
  var index = 0;

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      window.requestAnimationFrame(animateDiv);
    }

    window.addEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight);
  }

  var img1 = new Image(150, 150);

  const animateDiv = () => {
    if (ref.current) {
      const svg = document.getElementById("qr_code");
      const child: any = ref.current.firstChild
      const ctx: any = child.getContext('2d');
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      
      ctx.fillStyle = "#000000";

      ctx.fillStyle = COLORS[index % 5];
      
      ctx.beginPath();
      if (directionY == 0) {
        y = y + 1;
      }
      if (directionX == 0) {
        x = x + 1;
      }
      if (directionY == 1) {
        y = y - 1;
      }
      if (directionX == 1) {
        x = x - 1;
      }

      if (window.innerHeight < y + 150) {
        directionY = 1;
        index++;
      }
      if (window.innerWidth < x + 150) {
        directionX = 1;
        index++;
      }

      if (0 > y) {
        directionY = 0;
        index++;
      }
      if (0 > x) {
        directionX = 0;
        index++;
      }
      
      ctx.rect(x, y, QR_RECT, QR_RECT);
      ctx.fill();

      if(svg && svg.firstChild) {
        var source: any = svg.firstChild;
        var xml = new XMLSerializer().serializeToString(source);
        var svg64 = btoa(xml);
        var b64Start = 'data:image/svg+xml;base64,';
        var image64 = b64Start + svg64;
        img1.src = image64;
        ctx.drawImage(img1, x + 10, y + 10, 130, 130);
      }
      window.requestAnimationFrame(animateDiv);
    }
  }

  return (
    <div className={style.Code}>
      <div className={style.CodeCanvas} ref={ref}>
        <canvas width={windowWidth} height={windowHeight}/>
      </div>
      <div className={style.ShareTwitter}
        onClick={() => {
          window.location.href = "https://twitter.com/intent/tweet?hashtags=coinbass&url=https://coinbass.xyz/code/" + params.id + "&text=" + JSON.stringify("Superbowl Ad inspired address viewer. Check out my wallet address @coinbase").slice(1, -1);
        }}>
        Share on Twitter
      </div>

      <div className={style.ShareOwn}
        onClick={() => {
          window.location.href = "/";
        }}>
        Make My Own
      </div>
      <div className={style.QRmate}
          style={{
            "top": -190 + "px",
            "left": -190 + "px"
           }}
           id={"qr_code"}>
        <QRCode
            level="Q"
            style={{ width: 128 }}
            bgColor={"rgba(0, 0, 0, 0)"}
            value={JSON.stringify({
              id: 928328,
              name: params.id,
              insider: true,
            })}/>
      </div>
    </div>
  );
}

export default Code;


